<template>
  <!-- 审批流管理 -->
  <div class="app-container">
    <el-form ref="form" :model="form" label-width="120px" style="display: flex;">
      <div class="el-lt" style="width:85%;">
        <el-row :span="24" class="row-input" :class="{ 'show-row': showRow }">
          <el-col :span="8">
            <el-form-item label="采购订单号" :class="$i18n.locale">
              <el-input v-model="form.purchaseOrderCode" :placeholder="$t('page.inputPlaceholder')" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="STYLE" :class="$i18n.locale">
              <el-select v-model="form.style" value-key="id" clearable filterable>
                <el-option
                  v-for="item in styleOptions"
                  :key="item.id"
                  :label="item.styleName"
                  :value="item.styleName"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="供应商" prop="vendorId" :class="$i18n.locale">
              <el-select v-model="form.vendorId" clearable filterable :placeholder="$t('page.selectPlaceholder')">
                <el-option
                  v-for="item in vendorOptions"
                  :key="item.id"
                  :label="item.vendorName"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="18">
            <el-form-item label="预计交货日期" :class="$i18n.locale">
              <el-date-picker
                v-model="form.startPredictDeliveryDate"
                value-format="yyyy-MM-dd"
                placeholder="开始时间"
              /> —
              <el-date-picker
                v-model="form.endPredictDeliveryDate"
                value-format="yyyy-MM-dd"
                placeholder="结束时间"
              />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="订单状态" :class="$i18n.locale">
              <el-select v-model="form.status" clearable :placeholder="$t('page.selectPlaceholder')">
                <el-option label="已生效" value="1" />
                <el-option label="已转收货" value="2" />
                <el-option label="已完成" value="3" />

              </el-select>
            </el-form-item>
          </el-col>
        </el-row>

        <!-- <el-row :span="24" class="mb-3">
          <el-col :span="24">
            <ImportButton export-key="wms_out2c_shipping_info" :params="form" />
          </el-col>
        </el-row> -->
      </div>
      <el-row class="el-rt" style="width:25%;">
        <el-col class="row-center">
          <el-button type="primary" @click="queryClick()">{{ $t('page.search') }}</el-button>
          <el-button @click="resetClick()">{{ $t('page.reset') }}</el-button>
          <el-button type="text" @click="showRowClick()">{{ showRow ? $t('page.hide') : $t('page.expand') }}</el-button>
        </el-col>
      </el-row>

    </el-form>
    <el-table
      ref="multipleTable"
      :loading="TableLoading"
      class="mb-3"
      :data="tableDatas"
      :header-cell-style="{background:'#fafafa'}"
    >
      <el-table-column
        type="selection"
      />
      <el-table-column
        :label="$t('page.No')"
        type="index"
        width="80"
        align="center"
      >
        <template slot-scope="scope">
          {{ scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="purchaseOrderCode"
        label="采购订单号"
        width="180"
      />
      <el-table-column
        prop="orderStatus"
        label="订单状态"
        align="center"
      >
        <template slot-scope="scope">
          <p v-if="scope.row.orderStatus=='1'">已生效</p>
          <p v-if="scope.row.orderStatus=='2'">已转收货</p>
          <p v-if="scope.row.orderStatus=='3'">已完成</p>
        </template>
      </el-table-column>
      <el-table-column
        prop="vendorName"
        label="供应商"
        align="center"
      />
      <el-table-column
        prop="style"
        label="STYLE"
        align="center"
      />

      <el-table-column
        prop="predictDeliveryDate"
        label="预计交货日期"
        align="center"
      />
      <el-table-column
        prop="totalPairs"
        label="总数量"
        align="center"
      />
      <el-table-column
        prop="finishPairs"
        label="已完成数量"
        align="center"
      />
      <el-table-column
        prop="unFinishPairs"
        label="未完成数量"
        align="center"
      />

      <el-table-column
        fixed="right"
        :label="$t('page.operate')"
        width="150"
        align="center"
      >
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="handledetail(scope.row) ">
            <p v-if="scope.row.orderStatus == '1'">修改</p>
          </el-button>

        </template>
      </el-table-column>

    </el-table>
    <div class="block">
      <pagination
        :pager="pager"
        @pagination="_produceProgresspage"
      />
    </div>
    <el-dialog title="提示" :visible.sync="dialogFormVisible">
      <el-form :model="formwarehous" label-width="100px">
        <div style="margin:20px 20px"><span>采购订单号 : </span><span>{{ purchaseOrderCode }}<span /></span></div>

        <el-row :span="24">
          <el-col :span="10">
            <el-form-item label="已完成数量">
              <el-input v-model="finishPairs" :placeholder="$t('page.inputPlaceholder')" />
            </el-form-item>
          </el-col>
        </el-row>

      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" :loading="upLoding" @click="sure()">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { produceProgresspage, updateFinishPairs, queryVendorList, queryStyleList } from '@/api/scm-api'
import Pagination from '@/components/Pagination'

export default {
  components: { Pagination },
  data() {
    return {
      upLoding: false,
      formwarehous: {},
      dialogFormVisible: false,
      TableLoading: false,
      styleOptions: [],
      vendorOptions: [],
      showRow: false, // 切换显示input
      tableDatas: [],
      pager: {
        size: 10,
        current: 1,
        total: 0
      },
      form: {
      },
      purchaseOrderCode: '',
      finishPairs: '',
      codedatas: {}
    }
  },
  computed: {
    // 生成对应的请求参数
    queryParameter() {
      return Object.assign({}, this.pager, this.form)
    }
  },
  mounted() {
    this._queryStyleList()
    this._queryVendorList()
    this._produceProgresspage()
  },
  methods: {
    // 点击查询获取信息
    queryClick(page) {
      if (page) this.pager.current = 1
      this._produceProgresspage(this.queryParameter)
    },
    // 获取style数据
    async _queryStyleList() {
      const { datas } = await queryStyleList()
      this.styleOptions = datas
    },
    // 供应商
    async _queryVendorList() {
      const { datas } = await queryVendorList()
      this.vendorOptions = datas
    },
    // 点击重置清空文本框信息
    resetClick() {
      this.form = {}
      this.queryClick(1)
    },
    // 点击展开控制文本框的显示隐藏
    showRowClick() {
      this.showRow = !this.showRow
    },
    // 默认查询
    async _produceProgresspage(pager) {
      try {
        this.TableLoading = true
        pager && Object.assign(this.pager, pager)
        const { datas } = await produceProgresspage(this.queryParameter)
        this.tableDatas = datas.records
        this.pager = datas.pager
      } finally {
        this.TableLoading = false
      }
    },
    //  列表分页方法
    handleSizeChange(val) {
      this.pager.size = val
      this._produceProgresspage(this.queryParameter)
    },
    handleCurrentChange(val) {
      this.pager.current = val
      this._produceProgresspage(this.queryParameter)
    },
    handledetail(row) {
      if (this.dialogFormVisible === false) {
        this.finishPairs = ''
      }
      this.dialogFormVisible = true
      this.purchaseOrderCode = row.purchaseOrderCode
      this.codedatas = {
        id: row.id,
        purchaseOrderCode: row.purchaseOrderCode
      }
    },
    // 修改数量
    sure() {
      this.codedatas.finishPairs = this.finishPairs
      this._updateFinishPairs(this.codedatas)
    },
    async _updateFinishPairs(data) {
      try {
        this.upLoding = true
        const { code, msg } = await updateFinishPairs(data)
        if (code === 0) {
          this.$notify({
            title: msg,
            message: msg,
            type: 'success'
          })
          this.upLoding = false
          this.dialogFormVisible = false
          this.finishPairs = ''
          this._produceProgresspage(this.queryParameter)
        }
      } finally {
        this.upLoding = false
      }
    }

  }
}
</script>
<style lang="scss">
.create-overseas .el-form-item__error {
  width: 86%;
  text-align: right;
}
</style>
