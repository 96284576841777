<template>
  <div class="paging mt-2 mr-4" :class="{'flex-end': end}">
    <el-pagination
      :current-page="pager.current"
      :page-sizes="pageSizes"
      :page-size="pager.size"
      layout="total, sizes, prev, pager, next, jumper"
      :total="pager.total"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />
  </div>
</template>

<script>
export default {
  name: 'Pagination',
  props: {
    pager: {
      type: Object,
      default: () => ({
        current: 1,
        size: 10,
        total: 0
      })
    },
    end: {
      type: Boolean,
      default: true
    },
    pageSizes: {
      type: Array,
      default: () => [10, 20, 40, 100, 200, 300, 400, 1000]
    }
  },
  methods: {
    handleSizeChange(val) {
      this.$emit('pagination', Object.assign({}, this.pager, { size: val, current: 1 }))
    },
    handleCurrentChange(val) {
      this.$emit('pagination', Object.assign({}, this.pager, { current: val }))
    }
  }
}
</script>

<style lang="scss" scoped>
.paging {
  display: flex;
}
.flex-end {
  justify-content:flex-start;
}
</style>
